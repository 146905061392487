<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{
                            $t("message.new_m", {
                                m: $t("message.user"),
                            })
                        }}</p>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          v-loading="loadingData"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.nameFull")}}</span>
                  <el-form-item prop="name">
                    <el-input
                      :placeholder=" $t('message.nameFull') "
                      v-model="form.name"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{ $t("message.phone_number") }}</span>
                  <el-form-item prop="phone_number">
                    <el-input
                      :placeholder="$t('message.phone_number')"
                      type="tel"
                      v-model="form.phone_number"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{ $t("message.password") }}</span>
                  <el-form-item prop="password">
                    <el-input
                      :placeholder="$t('message.password')"
                      v-model="form.password"
                      type="password"
                      autocomplete="off"
                      size="medium"
                      show-password
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{ $t("message.confirm_password") }}</span>
                  <el-form-item prop="password_confirmation">
                    <el-input
                      :placeholder="$t('message.confirm_password')"
                      v-model="form.password_confirmation"
                      type="password"
                      autocomplete="off"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{ $t("message.email") }}</span>
                  <el-form-item prop="email">
                    <el-input
                      :placeholder="$t('message.email')"
                      v-model="form.email"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{ $t("message.last_name") }}</span>
                  <el-form-item prop="last_name">
                    <el-input
                      :placeholder="$t('message.last_name')"
                      v-model="form.last_name"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.middle_name")}}</span>
                  <el-form-item prop="middle_name">
                    <el-input
                      :placeholder="$t('message.middle_name')"
                      v-model="form.middle_name"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.gender")}}</span>
                  <el-form-item prop="gender_id">
                    <select-gender
                      :id="form.gender_id"
                      v-model="form.gender_id"
                    ></select-gender>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.status")}}</span>
                  <el-form-item prop="status">
                    <select-status
                      :id="form.status_id"
                      :table_name="'users'"
                      v-model="form.status_id"
                    ></select-status>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectGender from "@/components/filters/inventory/select-gender";
import selectStatus from "@/components/filters/inventory/select-status";
export default {
  mixins: [drawer, form, show],
  name: "UserController",
  components: {
    selectGender,
    selectStatus,
  },
  computed: {
    ...mapGetters({
      rules: "users/rules",
      model: "users/model",
      columns: "users/columns",
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      update: "users/update",
      show: "users/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              console.log("error is happening");
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
